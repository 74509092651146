<template>
  <div class="page layout">
    <section class="banner panel">
      <img v-if="isMobile" src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/banner.b51fe864.gif" width="1920" height="980" />
      <ZVideo v-else imgSrc="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/banner.bdf05bcd.jpg" videoSrc="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/video/o3.mp4" />
      <!-- <img src="../../assets/img/index/banner.jpg" width="1920" height="980" alt="banner" /> -->
      <div class="summary">
        <h2><strong class="ZAI">零载汽车</strong>第一个机器人汽车品牌</h2>
        <p>根据无人车的特点和末端运营场景的探索，<br class="mShow" />进行无人车新交互方式设计</p>
        <p>打造高度自然简单的无人车使用体验。<br class="mShow" />让无人车商用更简单、易维护</p>
        <p>通过AI技术能力和自动驾驶算法，融合工业制造能力，<br class="mShow" />让末端运输更安全、更绿色、更高效。</p>
      </div>
    </section>

    <section class="banner2 panel">
      <div class="summary">
        <h2><strong class="ZAI">零载</strong>开拓者号</h2>
        <p class="desc">未来无人智能物流的开拓者</p>
        <span class="go" @click="dialogVisible = true">
          看看<strong>零载</strong>是如何改变末端工作的 
          <svg class="svg" width="40" height="40" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#000" fill="none" fill-rule="evenodd">
              <circle stroke-width="2" cx="40" cy="40" r="38"/>
              <path stroke-width="4" d="M26 15l39 26-39 25z"/>
            </g>
          </svg>
        </span>
      </div>
      <img src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/banner.b25b5b13.jpg" width="1920" height="980" alt="banner" />
    </section>

    <section class="matcycle4 panel">
      <div class="summary">
        <h3>MatCycle 4</h3>
        <p class="desc">矩阵L4自动驾驶系统漫赛</p>
      </div>
      <ul class="flex">
        <li><img src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/mat_cycle4_left.cf6aaf26.jpg" width="930" height="616" alt="导航示意图" /></li>
        <li><img src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/mat_cycle4_right.6a320902.jpg" width="930" height="616" alt="导航线路图" /></li>
      </ul>
      <div class="conclusion">
        <p>MatCycle4 矩阵L4自动驾驶系统基于车规级量产车型打造的自动驾驶方案，<br class="mHide" />采用多传感器融合感知方案和深度学习算法，协同矩阵自研智能系统，打造无人车驾驶生产力级的解决方案。<br />使矩阵自动驾驶系统在快递、厂区等配送场景实现大规模应用</p>
      </div>
    </section>

    <section class="news">
      <h4 class="newsTitle">零载资讯</h4>
      <div class="newsMain">
        <div class="cover">
          <img src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/news_1.0720a37c.jpg" width="720" height="405" alt="华为生态大会暨零载探索者无人车发布" />
        </div>
        <div class="summary">
          <div class="con">
            <h3>华为生态大会暨零载开拓者无人车发布</h3>
            <p>随着VR、AR、AI、5G等技术在物流领域的融合不断拓展，物流中渗透的元宇宙内涵也在加强。2021华为智能汽车解决方案生态论坛，华为生态企业发布首个机器人汽车的品牌“零载汽车”，并亮相了其首款搭载华为MDC计算平台，采用最新无人驾驶技术的L4级快递无人驾驶物流车“开拓者号”。新一代 L4级无人配送物流车，利用数字孪生的虚拟场景，可迅速低成本地积累数据与场景，为真实落地做贡献。j矩阵数据发布机器人汽车品牌，为元宇宙布局打下基础。</p>
          </div>
          <a href="https://www.huawei.com/minisite/ias-partner-forum-2021/cn/" target="_blank" class="goBtn">阅读详情</a>
        </div>
      </div>
      <div class="swiperWrap">
          <!-- <a class="newsItem" href="https://mp.weixin.qq.com/s/nBN8Vmcjm9mOTOoCbgE5hg" target="_blank">
            <img src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/news_5.262bf857.png" width="440" height="247" alt="矩阵数据科技荣获工信部颁发未来出行创新应用奖" >
            <p>矩阵数据荣获【华为智能汽车解决方案优秀合作伙伴】</p>
          </a> -->
          <a class="newsItem" href="http://sd.iqilu.com/share/video/4963115" target="_blank">
            <img src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/news_2.d42afda5.jpg" width="440" height="247" alt="获得全国首张新能源物流配送无人车牌照" >
            <p>获得全国首张新能源物流配送无人车牌照</p>
          </a>
          <a class="newsItem" href="https://live.cctvnews.cctv.com/h-ui/index.html?liveRoomNumber=649256055271985176&toc_style_id=feeds_only_back&share_to=copy_url&track_id=5436c02c-ac99-47d1-8243-4e7fb0526a4d" target="_blank">
            <img src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/news_3.567cc252.jpg" width="440" height="247" alt="央视新闻直播报道零载。无人车" >
            <p>央视新闻直播报道零载·无人车</p>
          </a>
          <a class="newsItem" href="https://www.sohu.com/a/492795584_109373" target="_blank">
            <img src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/portal/prod/0.0.1/news_4.cc785e22.jpg" width="440" height="247" alt="矩阵数据科技荣获工信部颁发未来出行创新应用奖" >
            <p>矩阵数据科技荣获工信部颁发未来出行创新应用奖</p>
          </a>
      </div>
    </section>

    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="vDialog"
      width="80%"
      :destroy-on-close="true"
      @opened="playVideo"
    >
      <video
        ref="video2"
        class="video"
        :autoplay="dialogVisible"
        webkit-playsinline
        playsinline
        x5-playsinline
        loop
        preload="preload"
        name="media"
        width="960"
        height="540"
      >
        <source src="//jz-static-files.oss-cn-hangzhou.aliyuncs.com/video/introduction.mp4" type="video/mp4" />
      </video>
    </el-dialog>
  </div>
</template>
<script>
import ZVideo from '../../components/ZVideo';

export default {
  name:'Zzai',
  components: {
    ZVideo,
  },
  data() {
    const u = navigator.userAgent;
    const isMobile = !!u.match(/AppleWebKit.*Mobile.*/);

    return {
      isMobile,
      dialogVisible: false,
    }
  },
  methods: {
    playVideo() {
      const { video2 } = this.$refs;

      video2 && video2.play();
    }
  }
}
</script>

<style lang="scss">
.vDialog {
  .el-dialog__header {
    display: none;
  }

  // .el-dialog__headerbtn {
  //   top: -38px;
  //   right: 0;
  //   padding: 4px;
  //   z-index: 1;
  //   background-color: #000;

  //   .el-dialog__close {
  //     display: block;
  //     color: #fff;
  //     font-size: 36px;
  //   }
  // }

  .el-dialog__body {
    padding: 6px;
    background-color: #000;

    video {
      display: block;
      width: 100%;
      height: auto;
    }
  }

}
</style>

<style lang="scss" scoped>
// .page {}
.panel {
  position: relative;
  text-align: center;
  font-size: 20px;
  line-height: 1.7;

  img {
    display: block;
    object-fit: cover;
  }

  .summary {
    h3 {
      font-size: 60px;
      font-weight: 500;
    }

    .desc {
      font-size: 30px;
    }
  }
}

.banner {
  img {
    width: 100%;
    height: auto;
  }

  .summary {
    position: absolute;
    left: 0;
    bottom: 140px;
    width: 100%;
    color: #fff;

    h2 {
      margin-bottom: 16px;
      color: #fff;
      font-size: 45px;
      font-style: italic;
      font-weight: 400;

      .ZAI {
        &::after {
          margin: 0 6px 0 12px;
          width: 24px;
          height: 24px;
          border-width: 6px;
        }
      }
    }
  }
}

.banner2 {
  img {
    width: 100%;
    height: 980px;
  }

  .summary {
    position: absolute;
    top: 160px;
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);

    h2 {
      font-size: 80px;

      .ZAI {
        &::after {
          margin: 0 16px 0 16px;
          width: 43px;
          height: 43px;
          border-width: 11px;
        }
      }
    }

    .desc {
      letter-spacing: 8px;
    }

    .go {
      margin-top: 20px;
      display: inline-flex;
      align-items: center;
      height: 74px;
      padding: 0 30px;
      font-size: 30px;
      font-style: italic;
      font-weight: 300;
      color: #000;
      border: 2px solid #000;
      cursor: pointer;

      svg {
        margin-left: 12px;
      }
    }
  }
}

.matcycle4 {
  padding: 130px 0 100px;
  color: #fff;
  background-color: #151518;

  .summary {
    margin-bottom: 40px;

    h3 {
      color: #fff;
      font-weight: bold;
      font-family: Helvetica;
    }
  }

  .flex {
    display: flex;
    margin-bottom: 52px;

    li {
      flex: 1;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.news {
  position: relative;
  margin: 112px 64px;

  .newsTitle {
    margin-left: 50%;
    padding-left: 60px;
    font-size: 30px;
    line-height: 2;
    font-style: italic;
    font-weight: 300;
  }

  .newsMain {
    display: flex;
    margin-top: -60px;
    
    .cover {
      width: 50%;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    
    .summary {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 50%;
      padding-left: 60px;
      padding-top: 60px;
      
      .con {
        flex: 1;
        width: 100%;
        font-size: 16px;
        line-height: 1.5;

        h3 {
          width: 100%;
          padding: 12px 0;
          font-size: 34px;
          line-height: 40px;
        }
      }

      .goBtn {
        margin: 40px 0;
        font-weight: 300;
      }
    }
  }

  .swiperWrap {
    position: relative;
    margin-top: 72px;
    display: flex;

    .newsItem {
      display: block;

      & + .newsItem {
        margin-left: 52px;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      p {
        margin-top: 16px;
        font-size: 18px;
        color: #000;
        text-align: center;
      }
    }
  }
}


@media only screen and (max-width: 500px) {
  .page {
    padding: 0;
  }

  .panel {
    font-size: 12px;

    .summary {
      h3 {
        font-size: 30px;
      }

      .desc {
        font-size: 14px;
      }
    }
  }

  .banner {
    margin-bottom: 16px;
  
    .summary {
      position: static;
      padding: 16px;
      color: #000;

      h2 {
        margin: 0 0 8px;
        color: #000;
        font-size: 20px;

        .ZAI {
          &::after {
            margin: 0 4px 0 8px;
            width: 14px;
            height: 14px;
            border-width: 2px;
          }
        }
      }
    }
  }

  .banner2 {
    img {
      width: 100%;
      height: 480px;
    }

    .summary {
      position: absolute;
      top: 60px;

      h2 {
        font-size: 30px;

        .ZAI {
          &::after {
            margin: 0 8px 0 8px;
            width: 20px;
            height: 20px;
            border-width: 3px;
          }
        }
      }

      .desc {
        letter-spacing: 4px;
      }

      .go {
        margin-top: 10px;
        height: 36px;
        padding: 0 12px;
        font-size: 16px;
        border: 1px solid #000;

        svg {
          margin-left: 6px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .matcycle4 {
    padding: 60px 0 50px;

    .summary {
      margin-bottom: 20px;
    }

    .flex {
      margin-bottom: 32px;
    }

    .conclusion {
      padding: 0 20px;
      text-align: justify;
    }

  }

  .news {
    position: relative;
    margin: 50px 16px;

    .newsTitle {
      margin-left: 0;
      padding-left: 0;
    }

    .newsMain {
      display: block;
      margin-top: 0;
      
      .cover {
        width: 100%;
      }
      
      .summary {
        width: 100%;
        padding-left: 0;
        padding-top: 0;
        
        .con {
          font-size: 12px;
          text-align: justify;

          h3 {
            padding: 16px 0 8px;
            font-size: 18px;
            line-height: 26px;
          }
        }

        .goBtn {
          margin: 8px 0 24px;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }

    .swiperWrap {
      position: relative;
      margin-top: 0;
      display: flex;

      .newsItem {
        & + .newsItem {
          margin-left: 12px;
        }

        p {
          margin-top: 8px;
          font-size: 12px;
          text-align: justify;
        }
      }
    }
  }
}
</style>
